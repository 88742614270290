import React, { createContext, useState, useEffect } from 'react';
import { auth, db } from '../firebase'; // Import your Firebase configuration
import { collection, doc, getDocs, query, where } from 'firebase/firestore';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [hasAccess, setHasAccess] = useState(false);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((authUser) => {
      setUser(authUser);
    });

    return () => {
      unsubscribe();
    };
  }, []);

  useEffect(() => {
    const checkAccess = async () => {
      if (user) {
        const q = query(
          collection(
            doc(db, 'users', user.uid),
            'subscriptions'
          ),
          where('status', 'in', ['trialing', 'active'])
        );
        const querySnapshot = await getDocs(q);
    
        if (!querySnapshot.empty) {
          setHasAccess(true);
        } else {
          setHasAccess(false);
        }
      } else {
        setHasAccess(false);
      }
    };    

    checkAccess();
  }, [user]);

  return (
    <AuthContext.Provider value={{ user, hasAccess }}>
      {children}
    </AuthContext.Provider>
  );
};

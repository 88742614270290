import React, { useState, useEffect } from 'react';
import { db, auth } from './firebase'; // Import your Firebase configuration
import { onAuthStateChanged, GoogleAuthProvider, signInWithPopup, signInAnonymously, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { loadStripe } from '@stripe/stripe-js';
import { collection, doc, getDoc } from 'firebase/firestore';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const stripePromise = loadStripe('pk_test_51MydzqJNjqMaduwruz29MsAkYmTuX46sO5jbbGeM2w8uzMirfUnIuMcShCwmNrqtDiaD5nLTkaf8ZUf4z2WXyomj00YJoK5CAZ'); // Replace with your Stripe public key

const Auth = () => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      setUser(user);
      if (user) {
        const userRef = doc(db, 'users', user.uid);
        const userDoc = await getDoc(userRef);
        if (userDoc.exists) {
          const customerId = userDoc.data().stripeCustomerId;
          handleSubscriptionUpdates(customerId);
        }
      }
    });

    return () => unsubscribe();
  }, []);

  const signInWithGoogle = async () => {
    const provider = new GoogleAuthProvider();
    await signInWithPopup(auth, provider);
  };

  const createSubscription = async () => {
    const userRef = db.collection('users').doc(user.uid);
    const stripe = await stripePromise;
    const sessionRef = await userRef.collection('checkout_sessions').add({
      price: 'price_1Myhx2JNjqMaduwrCWiXJskZ', // Replace with your Stripe Price ID
      success_url: window.location.origin + '?session_id={CHECKOUT_SESSION_ID}',
      cancel_url: window.location.origin,
    });
    const sessionId = (await sessionRef.get()).data().id;
    await stripe.redirectToCheckout({ sessionId });
  };

  const handleSubscriptionUpdates = async (customerId) => {
    const subscriptionRef = db
      .collection('users')
      .doc(user.uid)
      .collection('subscriptions')
      .where('customer', '==', customerId)
      .where('status', 'in', ['trialing', 'active']);

    subscriptionRef.onSnapshot((snapshot) => {
      if (!snapshot.empty) {
        console.log('User has an active subscription');
      } else {
        console.log('User does not have an active subscription');
      }
    });
  };

  return (
    <div>
      {user ? (
        <>
          <h1>Welcome, {user.displayName || 'Guest'}!</h1>
          <button onClick={() => signOut(auth)}>Sign out</button>
          {!user.isAnonymous && (
            <button onClick={createSubscription}>Subscribe</button>
          )}
        </>
      ) : (
        <>
          <div>
            <FontAwesomeIcon icon={faUser} onClick={signInWithGoogle} />
          </div>
        </>
      )}

    </div>
  );
};

export default Auth;
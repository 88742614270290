import React from 'react';
//import ReactDOM from 'react-dom/client'; commented when reviewing page blank
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { register } from './serviceWorker';
// import * as serviceWorkerRegistration from './serviceWorkerRegistration';
// Import the functions you need from the SDKs you need

import { createRoot } from 'react-dom';

const root = document.getElementById('root');
createRoot(root).render(<App />);

reportWebVitals();

register();

import logo from './epic-tales-high-res-no-bg.png';
import './App.css';
import Home from './components/Home';
import Adventure from './components/Adventure';
import Achievements from './components/Achievements';
import Profile from './components/Profile';
import Subscription from './components/Subscription';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import Auth from './Auth';
import AdventureList from './components/AdventureList';
import { useContext } from 'react';
import { AuthContext, AuthProvider } from './components/AuthContext';
// Import Font Awesome components
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome, faBook, faTrophy, faUser, faDollarSign, faList } from '@fortawesome/free-solid-svg-icons';
import Countdown from './components/Countdown';
import TermsOfUse from './components/TermsOfUse';
import PrivacyPolicy from './components/PrivacyPolicy';
import Adsense from 'react-adsense';
import Footer from './components/Footer';


function App() {
  const getMostRecentSunday6amGMT = () => {
    const now = new Date();
    const gmtNow = new Date(now.valueOf() + now.getTimezoneOffset() * 60000);
    const dayOfWeek = gmtNow.getDay();
    const daysSinceSunday = dayOfWeek === 0 ? 0 : 7 - dayOfWeek;

    const sunday6amGMT = new Date(
      gmtNow.getFullYear(),
      gmtNow.getMonth(),
      gmtNow.getDate() + daysSinceSunday,
      6,
      0,
      0
    );
    return sunday6amGMT;
  };

  const getNewAdventureId = () => {
    const mostRecentSunday6amGMT = getMostRecentSunday6amGMT();
    const adventureNumber = Math.floor(mostRecentSunday6amGMT.getTime() / (1000 * 60 * 60 * 24 * 7));
    return `Adventure_${adventureNumber - 7836}`;
  };

  const [adventureId, setAdventureId] = useState(getNewAdventureId());

  useEffect(() => {
    const checkDateAndUpdateAdventure = () => {
      const now = new Date();
      const gmtNow = new Date(now.valueOf() + now.getTimezoneOffset() * 60000);
      if (gmtNow.getDay() === 0 && gmtNow.getHours() === 6 && gmtNow.getMinutes() === 0) {
        const newAdventureId = getNewAdventureId();
        if (newAdventureId !== adventureId) {
          setAdventureId(newAdventureId);
        }
      }
    };

    const timer = setInterval(() => {
      checkDateAndUpdateAdventure();
    }, 60000);

    return () => clearInterval(timer);
  }, [adventureId]);

  return (
    <AuthProvider>
      <Router>
        <div className="app-container">
          <div className="app-logo-container">
            <img src={logo} className="App-logo" alt="logo" />
          </div>
          <div className="App">
            <header>
              {/* 
              <div className="sign-in">
                <button className="sign-in-btn"><Auth /></button>
              </div>
              */}
              <nav>
                <ul>
                  <li>
                    <Link to="/"><FontAwesomeIcon icon={faHome} /></Link>
                  </li>
                  <li>
                    <Auth />
                  </li>
                  {/* Uncomment the following lines for future use
                  <li>
                    <Link to="/adventure"><FontAwesomeIcon icon={faBook} /></Link>
                  </li>

                  <li>
                    <Link to="/achievements"><FontAwesomeIcon icon={faTrophy} /></Link>
                  </li>
                  
                  <li>
                    <Link to="/profile"><FontAwesomeIcon icon={faUser} /></Link>
                  </li>
                  <li>
                    <Link to="/subscription"><FontAwesomeIcon icon={faDollarSign} /></Link>
                  </li>
                  <li>
                    <Link to="/adventures"><FontAwesomeIcon icon={faList} /></Link>
                  </li>
                  */}
                </ul>
              </nav>
              <Countdown />
            </header>
            <Routes>
              <Route path="/" element={<Adventure adventureId={adventureId} />} />
              {/*<Route path="/adventure/:adventureId" element={<Adventure />} /> 
              <Route path="/achievements" element={<Achievements />} />*/}
              {/* Uncomment the following lines for future use
              <Route path="/profile" element={<Profile />} />
              <Route path="/subscription" element={<Subscription />} />
              <Route path="/adventures" element={<AdventureList />} />
              */}
              <Route path="/terms-of-use" element={<TermsOfUse />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
}

export default App;
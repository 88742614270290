import React from 'react';

const Footer = () => {
  return (
    <footer className="footer">
      <a href="/terms-of-use">Terms of Use</a>
      <a href="/privacy-policy">Privacy Policy</a>
    </footer>
  );
};

export default Footer;
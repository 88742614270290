import React, { useContext, useEffect, useState } from 'react';
import { collection, doc, getDoc, getDocs, updateDoc } from "firebase/firestore";
import { auth, db } from '../firebase';
import { useParams } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import { achievements } from '../AchievementList';
import {
  getLocalStorageItem,
  setLocalStorageItem,
} from './localStorageHelper';
import {
  FacebookShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  FacebookIcon,
  TwitterIcon,
  WhatsappIcon,
} from 'react-share';

const Adventure = (props) => {
  const { user } = useContext(AuthContext);
  const { adventureId } = props;
  const [adventureData, setAdventureData] = useState(null);
  const [currentPage, setCurrentPage] = useState(null);
  const [loadingProgress, setLoadingProgress] = useState(true);
  const [userAchievements, setUserAchievements] = useState({});
  const [pages, setPages] = useState({});


  useEffect(() => {
    const fetchAdventure = async () => {
      setLoadingProgress(true);
      try {
        const adventureRef = doc(db, "adventures", "Adventure_1");
        const adventureDoc = await getDoc(adventureRef);

        if (adventureDoc.exists()) {
          const data = adventureDoc.data();
          setAdventureData(data);

          const pages = {};
          const pagesSnapshot = await getDocs(collection(adventureRef, "pages"));
          pagesSnapshot.forEach((doc) => {
            const pageData = doc.data();
            pages[pageData.id] = pageData;
          });

          setPages(pages);

          // 2. Retrieve the saved page ID from local storage
          const lastPage = localStorage.getItem('lastPage');

          // Check if there is a saved page ID, if not, use the starting page
          if (lastPage) {
            setCurrentPage(lastPage);
          } else {
            setCurrentPage(data.startPage);
          }

          //const progress = await loadProgress();
          //setCurrentPage(progress || data.startPage);
        }
      } catch (error) {
        console.error("Error fetching adventure data:", error);
      } finally {
        setLoadingProgress(false);
      }
    };


    fetchAdventure();
  }, [adventureId]);

  const saveProgress = (pageId) => {
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      updateDoc(userRef, { progress: pageId });
    } else {
      localStorage.setItem('progress', pageId);
    }
  };

  const loadUserData = async () => {
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      return userDoc.data();
    } else {
      return {
        progress: localStorage.getItem('progress'),
        achievements: JSON.parse(localStorage.getItem('achievements') || '{}'),
      };
    }
  };

  const loadProgress = async () => {
    if (user) {
      const userRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userRef);
      return userDoc.data().progress;
    } else {
      return localStorage.getItem('progress');
    }
  };

  const handleChoice = (nextPageId) => {
    setCurrentPage(nextPageId);
    saveProgress(nextPageId);

    // 1. Save the current page ID to local storage
    localStorage.setItem('lastPage', nextPageId);
  };

  const updateAchievements = async (userId, newAchievements) => {
    const userRef = doc(collection(db, 'users'), userId);
    const userDoc = await getDoc(userRef);
    const userData = userDoc.data();

    // Merge new achievements with existing ones
    const updatedAchievements = { ...userData.achievements, ...newAchievements };

    // Update the user's achievements in Firestore
    await updateDoc(userRef, { achievements: updatedAchievements });

    // Update achievements in local storage if the user is a guest
    if (!user) {
      localStorage.setItem('achievements', JSON.stringify(updatedAchievements));
    }
  };

  const handleEnding = async (ending) => {
    const newAchievements = {}; // A new object to store any achievements the user has earned

    //Check for specific achievements based on the ending type
    if (ending.type === 'good') {
      newAchievements.good_ending = true;

      //Increment the counter for good endings
      newAchievements.five_good_endings = (userAchievements.five_good_endings || 0) + 1;
    } else {
      //Increment the counter for bad endings
      newAchievements.ten_bad_endings = (userAchievements.ten_bad_endings || 0) + 1;
    }

    // Update user's achievements
    await updateAchievements(user?.uid, newAchievements);
  };

  const updateSharingAchievements = async () => {
    const userRef = doc(collection(db, 'users'), user.uid);
    const userDoc = await getDoc(userRef);
    const userData = userDoc.data();

    const newAchievements = {};

    // Increment the sharing counter
    const shareCount = (userData.achievements.share_count || 0) + 1;
    newAchievements.share_count = shareCount;

    if (shareCount === 1) {
      newAchievements.share_first_time = true;
    } else if (shareCount === 5) {
      newAchievements.share_five_times = true;
    } else if (shareCount === 10) {
      newAchievements.share_ten_times = true;
    }

    // Update the user's achievements in Firestore
    await updateDoc(userRef, { achievements: { ...userData.achievements, ...newAchievements } });

    // Update achievements in local storage if the user is a guest
    if (!user) {
      localStorage.setItem('achievements', JSON.stringify({ ...userData.achievements, ...newAchievements }));
    }
  };


  const shareAdventure = async () => {
    // ...existing code to share the adventure...

    // Update sharing achievements
    await updateSharingAchievements(user.uid);
  };


  const handleOptionClick = (nextPageId) => {
    if (pages[nextPageId]) {
      setCurrentPage(nextPageId);
      // 1. Save the current page ID to local storage
      localStorage.setItem('lastPage', nextPageId);
    } else {
      console.error(`Page with ID '${nextPageId}' not found.`);
      console.log('Choices for current page:', pages[currentPage]?.choices);
      console.log(
        "Problematic choice:",
        pages[currentPage]?.choices.find((choice) => choice.nextPage === undefined)
      );
    }
  };

  // UPDATE WEBSITE AND MESSAGE
  //
  //
  //
  //
  //

  const shareUrl = 'https://your-app-url.com';
  const shareMessage = currentPage
    ? `I'm playing this awesome adventure game, and I'm on page ${currentPage}! Join me at ${shareUrl}`
    : '';

  console.log('Adventure Data:', adventureData);
  console.log('Current Page:', currentPage);
  console.log('Pages:', pages);
  console.log('Current Page Data:', pages[currentPage]);

  return (
    <div>
      {!loadingProgress && adventureData && currentPage !== null ? (
        <>
          <div className="adventure-container">
            <div className="adventure-title">
              <h2>{pages[currentPage]?.title}</h2>
            </div>
            <div className="adventure-content">
              <p>{pages[currentPage]?.content}</p>
            </div>
            <div className="adventure-options">
              {pages[currentPage]?.choices?.map((choice, index) => (
                <button key={index} onClick={() => handleOptionClick(choice.targetId)}>
                  {choice.text}
                </button>
              ))}
            </div>
          </div>
          {adventureData?.pages?.[currentPage]?.ending && (
            <button onClick={() => handleEnding(adventureData?.pages?.[currentPage].ending)}>
              {adventureData?.pages?.[currentPage]?.ending?.type === 'good' ? 'Play again' : 'Wait until tomorrow'}
            </button>
          )}
        </>
      ) : (
        <p>Loading...</p>
      )}
      <div className="share-buttons">
        <FacebookShareButton url={shareUrl} quote={shareMessage}>
          <FacebookIcon size={32} round />
        </FacebookShareButton>
        <TwitterShareButton url={shareUrl} title={shareMessage}>
          <TwitterIcon size={32} round />
        </TwitterShareButton>
        <WhatsappShareButton url={shareUrl} title={shareMessage}>
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>
    </div>
  );
};

export default Adventure;